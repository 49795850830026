<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import { apiRequest } from "@/helpers/api-call";
//import Multiselect from "vue-multiselect";
//import VueAxios from 'vue-axios'
import axios from "axios";
/**
 * Form validation component
 */
export default {
    page: {
        title: "Upload d'APK",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "CHARGER l'APK ",
            file: '',
            $success: '',
            uploadPercentage: 0,
            downloadLink: "https://rmobility.raynis.co/assets/app/rmobility.apk",
            downloadLinkB: "https://rmobility.raynis.co/assets/app/Asso.apk",
        };
    },
    mounted() {
        this.init()
    },
    methods: {

        copyToClipboard() {
            const inputElement = this.$refs.downloadLinkInput;

            // Select the text in the input field
            inputElement.select();
            inputElement.setSelectionRange(0, 99999); // For mobile devices

            // Copy the selected text to the clipboard
            document.execCommand("copy");

            // Deselect the text
            inputElement.setSelectionRange(0, 0);

            // Optionally, provide some feedback to the user
            this.$toast.success("Lien copié", {
                position: "bottom-right",
                timeout: 1500,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        },
        copyToClipboardB() {
            const inputElement = this.$refs.downloadLinkInputB;

            // Select the text in the input field
            inputElement.select();
            inputElement.setSelectionRange(0, 99999); // For mobile devices

            // Copy the selected text to the clipboard
            document.execCommand("copy");

            // Deselect the text
            inputElement.setSelectionRange(0, 0);

            // Optionally, provide some feedback to the user
            this.$toast.success("Lien copié", {
                position: "bottom-right",
                timeout: 1500,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        },
        

        submitFile: function () {

            if (!this.file) {

                this.$toast.error("Veuillez choisir un fichier", {
                    position: "bottom-right",
                    timeout: 1500,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                });

            }


            this.file = this.$refs.file.files[0];
            let formData = new FormData();
            formData.append('file', this.file, 'rmobility.apk');
            axios.post('https://images.raynis.co/apkupload.php', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function (progressEvent) {
                        this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                        if (this.uploadPercentage > 0) {
                            this.$refs.progressBar.classList.add('green');
                        }
                    }.bind(this)
                })
                .then((response) => {
                    if (!response.data) {
                        this.$toast.error("Une erreur est survenue", {
                            position: "bottom-right",
                            timeout: 1500,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                    } else if (response.data) {
                        console.log('data', response.data);
                        this.success = response.data;
                        this.$toast.success("Téléversement réussi", {
                            position: "bottom-right",
                            timeout: 2500,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true,
                            rtl: false,
                        });
                        this.$refs.progressBar.classList = '';
                        this.success = '';
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });


            this.$refs.file.value = '';


        },
        submitFileAsso: function () {

if (!this.file) {

    this.$toast.error("Veuillez choisir un fichier", {
        position: "bottom-right",
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
    });

}


this.file = this.$refs.file.files[0];
let formData = new FormData();
formData.append('file', this.file, 'Asso.apk');
axios.post('https://images.raynis.co/apkuploadasso.php', formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            if (this.uploadPercentage > 0) {
                this.$refs.progressBar.classList.add('green');
            }
        }.bind(this)
    })
    .then((response) => {
        if (!response.data) {
            this.$toast.error("Une erreur est survenue", {
                position: "bottom-right",
                timeout: 1500,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
        } else if (response.data) {
            console.log('data', response.data);
            this.success = response.data;
            this.$toast.success("Téléversement réussi", {
                position: "bottom-right",
                timeout: 2500,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
            });
            this.$refs.progressBar.classList = '';
            this.success = '';
        }
    })
    .catch(function (error) {
        console.log(error);
    });


this.$refs.file.value = '';


},
        /*
        Handles a change on the file upload
      */
        handleFileUpload() {
            this.file = this.$refs.file.files[0];

        }
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-6 card">
            <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                    <template v-slot:title>
                        <a class="font-weight-bold active">RMobility</a>
                    </template>

                    <div class="container ">
                        <div class="large-12 medium-12 small-12 cell card-body">
                            <div class="form-group">
                                <label for="file" class="form-label">Charger l'APK des clients
                                </label>
                                <br>
                                <br>
                                <input style="background-color: none !important;" type="file" id="file" ref="file"
                                    v-on:change="handleFileUpload()" accept=".apk" />

                            </div>
                            <br>
                            <progress max="100" style="width: 80%; " ref="progressBar" class="progress-bar"
                                :class="{ 'green': uploadPercentage > 0 }" :value.prop="uploadPercentage"></progress>
                            <br> <br>
                            <button class="btn " style="background-color: #3aaa35; color: #fff;"
                                v-on:click="submitFile()">Téléverser</button>
                        </div>
                    </div>

                </b-tab>
                <b-tab title-link-class="p-3">
                    <template v-slot:title>
                        <a class="font-weight-bold active">RMobility Asso</a>
                    </template>

                    <div class="container ">
                        <div class="large-12 medium-12 small-12 cell card-body">
                            <div class="form-group">
                                <label for="file" class="form-label">Charger l'APK des Asso
                                </label>
                                <br>
                                <br>
                                <input style="background-color: none !important;" type="file" id="file" ref="file"
                                    v-on:change="handleFileUpload()" accept=".apk" />

                            </div>
                            <br>
                            <progress max="100" style="width: 80%; " ref="progressBar" class="progress-bar"
                                :class="{ 'green': uploadPercentage > 0 }" :value.prop="uploadPercentage"></progress>
                            <br> <br>
                            <button class="btn " style="background-color: #3aaa35; color: #fff;"
                                v-on:click="submitFileAsso()">Téléverser</button>
                        </div>
                    </div>

                </b-tab>
            </b-tabs>
        </div>
        </div>

       
        <div class="row">
            <div class="col-lg-6 card">
                <div class="container ">
                    <div class="large-12 medium-12 small-12 cell card-body">
                        <div class="form-group">
                            <label class="form-label">Lien de téléchargement &nbsp; <i @click="copyToClipboard"
                                    class="fas fa-copy copy"></i>
                            </label>
                            <br>
                            <br>
                            <div class="input-group">
                                <input class="form-control" style="background-color: aliceblue;" type="text"
                                    :value="downloadLink" ref="downloadLinkInput" @click="copyToClipboard" />
                                <div class="input-group-append" @click="copyToClipboard">
                                    <span class="input-group-text">
                                        <i class="fas fa-copy copy"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="large-12 medium-12 small-12 cell card-body">
                        <div class="form-group">
                            <label class="form-label">Lien Asso &nbsp; <i @click="copyToClipboardB"
                                    class="fas fa-copy copy"></i>
                            </label>
                            <br>
                            <br>
                            <div class="input-group">
                                <input class="form-control" style="background-color: aliceblue;" type="text"
                                    :value="downloadLinkB" ref="downloadLinkInputB" @click="copyToClipboardB" />
                                <div class="input-group-append" @click="copyToClipboard">
                                    <span class="input-group-text">
                                        <i class="fas fa-copy copy"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>
<style>
progress-bar {
    width: 0%;
    height: 20px;
    background-color: #fff;
    /* Set initial color (gray or any color you prefer) */
    transition: width 0.3s ease;
    /* Add a smooth transition effect */
}

.progress-bar.green {
    background-color: green;
    /* Set the color when the upload progresses */
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group-append {
    cursor: pointer;
}


.input {
    right: 13px;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 35px;
    bottom: 0;
    flex: 60%;
    margin-bottom: 5px;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
    background: #ffffff;
}

.input input {
    padding: 3px 3px 3px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    color: #495057;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
}

.input input:focus {
    border: 3px solid #3aaa35;
}

.text-success-save {
    background: #3AAA35;
    outline: none;
    border: none;
    color: #fff;
}

.inputCol {
    position: relative;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #3aaa35;
    border-color: #3aaa35;
}

.paging_simple_numbers {
    background: #3AAA35;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;
    box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
        -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
    color: white !important;
}

.paging_simple_numbers ul li button {
    color: #fff !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #252b3b;
    border-color: #252b3b;
}

.nav-tabs .nav-link a {
    color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
    color: #fff !important;
    font-weight: 600 !important;
    vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
    font-weight: 600;
    background: #3AAA35 !important;
    border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
    border-bottom: 1px solid #000000 !important;
}

.end-btb {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.space-record {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.start-btb {
    font-size: 28px;
    vertical-align: middle;
    border-radius: 20px;
    color: #3880ff;
    border: 1px solid #3880ff
}

.end-btb {
    font-size: 28px;
    vertical-align: middle;
    border-radius: 20px;
    color: #ff0000;
    border: 1px solid #ff0000;
}

.end-btb ion-icon {
    margin-bottom: -9px;
    margin-bottom: -5px;
    font-size: 30px;
}

.start-btb ion-icon {
    margin-bottom: -5px;
    font-size: 30px;
}

input::file-selector-button {
    background-color: #3AAA35 !important;
    color: white;
    border-color: #3AAA35 !important;
    border-radius: 10px;
    box-shadow: none;
    padding: 10px;
}

input::file-selector-button:hover {
    background-color: #fff !important;
    color: #000;

}

input[type="file"] {
    border: 1px solid black;
    border-radius: 10px 10px 10px 10px;
    border-style: ridge;
    background-color: #3baa3556;
    width: 100%;

}

input[type="file"]:hover {
    border: 1px solid black;
    border-radius: 10px 10px 10px 10px;
    border-style: ridge;
    background-color: #d4e3d4;
}

.copy:hover {
    color: #3AAA35;
}
</style>
